<template>
  <div>
    <br />
    <div class="row px-2">
      <div :class="errors != [] ? 'rounded bg-white border' : ''">
        <div v-for="(error, index) in errors" :key="index++" class="col-12">
          <div class="text-danger py-1">
            {{ index + " - " + error }}
          </div>
        </div>
      </div>
    </div>
    <br />
    <form @submit.prevent="save(saleItem)">
      <div class="row align-items-end">
        <div class="col-3">
          <label for="">Catégorie</label>
          <select v-model="section_reference" class="form-select">
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
          <div class="invalid-feedback">More example invalid feedback text</div>
        </div>

        <div class="col">
          <label for="">Produit</label>
          <select
            v-model="saleItem.item_reference"
            required
            class="form-select"
          >
            <option
              v-for="(item, index) in items.filter(
                (p) => p.section_reference == section_reference
              )"
              :key="index++"
              :value="item.reference"
            >
              {{ item.fullName }}
            </option>
          </select>
          <div class="invalid-feedback">More example invalid feedback text</div>
        </div>

        <div class="col-3">
          <label for=""> Stock <span class="text-danger">*</span> </label>
          <select
            v-model="saleItem.stock_reference"
            class="form-select"
            required
          >
            <option
              v-for="(stock, index) in filteredStocks"
              :key="index"
              :value="stock.reference"
            >
              {{ stock.fullName }} Quantité : {{ stock.quantity }}
              {{ stock.unit }}
            </option>
          </select>
        </div>
      </div>
      <br />
      <div class="row align-items-end">
        <div class="col-3">
          <label for="">Client <span class="text-danger">*</span> </label>
          <select
            :disabled="!customer_change && saleItem.customer_reference != null"
            v-model="saleItem.customer_reference"
            class="form-select"
            @change="customer_change = false"
          >
            <option selected value="--">--</option>
            <option
              v-for="(customer, index) in customers"
              :key="index++"
              :value="customer.reference"
              :style="
                customer.blocked || customer.fullName.includes('Bloqué')
                  ? { color: 'red' }
                  : ''
              "
            >
              {{ customer.fullName }}
            </option>
          </select>
          <div class="invalid-feedback">Example invalid select feedback</div>
        </div>

        <div class="col-auto">
          <label for=""></label>
          <button
            class="btn btn-warning"
            type="button"
            @click="
              customer_change
                ? (customer_change = false)
                : (customer_change = true)
            "
          >
            {{ !customer_change ? "Changer" : "Désactiver" }}
          </button>
        </div>

        <div class="col">
          <label for="">Quantité</label>
          <input v-model="saleItem.quantity" class="form-control" required />
        </div>
        <div class="col">
          <label for="">Prix</label>
          <input required v-model="saleItem.priceTTC" class="form-control" />
        </div>

        <div class="col">
          <label for=""> Remarque </label>
          <input v-model="saleItem.remark" class="form-control" />
        </div>
      </div>
      <div v-if="showError" class="alert alert-danger">
        Please fill in all the required fields.
      </div>
      <hr />
      <div class="row justify-content-end">
        <div class="col-5">
          <label for=""> Importer à partir d'un commande client </label>
          <select v-model="order_reference" class="form-select">
            <option
              v-for="(order, index) in orders"
              :key="index++"
              :value="order.reference"
            >
              {{ order.date | date }}-
              <span v-if="order.customer">
                {{ order.customer.fullName }}-
              </span>
              {{ order.reference }}
            </option>
          </select>
        </div>

        <div class="col">
          <label for=""> </label>
          <button
            class="btn btn-warning d-flex align-items-center"
            @click="importData(order_reference)"
          >
            <i class="bi bi-upload fs-4 me-2"></i>
            <span> Importer </span>
          </button>
        </div>

        <div class="col-auto">
          <label for=""></label>
          <button class="btn btn-success shadow" type="submit">
            <i class="bi bi-plus-circle"></i> Insérer
          </button>
        </div>

        <div class="col-auto">
          <label for=""></label>
          <button
            type="button"
            class="btn btn-secondary d-flex align-items-center"
            @click="refresh()"
          >
            <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
            <span> Actualiser </span>
          </button>
        </div>
      </div>
    </form>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix</th>
          <th scope="col">Quantité</th>
          <th scope="col">Total</th>
          <th scope="col">Stock</th>
          <th scope="col">Remarque</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in saleItems" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td class="">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC }}
            </span>
          </td>
          <td class="">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
            <span v-if="item.item">
              {{ item.item.measuring }}
            </span>
          </td>
          <td class="">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC }}
            </span>
          </td>
          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>
          </td>
          <td>
            {{ item.remark }}
          </td>

          <td class="d-flex">
            <button @click="remove(item)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="border-0">
          <td></td>

          <td>Total :</td>
          <td class=""></td>
          <td>
            <span
              v-if="totalQuantityItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalQuantityItems }}
            </span>
          </td>
          <td>
            <span
              v-if="totalPriceTTCItems != null"
              class="fw-bold bg-warning px-2 py-1 rounded"
            >
              {{ totalPriceTTCItems }}
            </span>
            DH
          </td>

          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives aux articles de la vente (
            Quantité totale : <strong>{{ totalQuantityItems }}</strong> Kg,
            Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          @click="next()"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      saleItem: {},
      section_reference: "",
      accept: false,
      customer_change: true,
      showError: false,
      errorFields: [],
      order_reference: "",
      // stocks: [ /* your array of stocks */ ]
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("sale", {
      saleItems: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
      errors: "getErrors",
    }),
    ...mapGetters("order", {
      orders: "getAll",
    }),
    ...mapGetters("stock", {
      stocks: "getStocks",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
    filteredStocks() {
      return this.stocks.filter(
        (stock) =>
          stock.item_reference === this.saleItem.item_reference &&
          stock.quantity > 0
      );
    },
    isSelectDisabled() {
      return this.filteredStocks.length === 1; // Disable select if there's only one filtered stock
    },
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("sale/storeItem", data);
      await (this.saleItem.item_reference = "");
      await (this.saleItem.stock_reference = "");
      await (this.saleItem.quantity = "");
      await (this.saleItem.priceTTC = "");
      await this.$store.dispatch("stock/getAll");
    },
    async remove(data) {
      let item = data.item;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("sale/destroyItem", data.id);
          }
        },
      });
    },
    async refresh() {
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("sale/getAllItems");
      this.$store.dispatch("customer/getAll");
      this.$store.dispatch("order/getAll");
      this.$store.dispatch("stock/getAll");
    },
    async next() {
      console.log(this.userRole);
      let order_reference = "";
      let customer_reference = this.saleItem.customer_reference;
      if (this.$route.query.order) order_reference = this.$route.query.order;
      if (this.$route.query.customer)
        customer_reference = this.$route.query.customer;

      if (this.userRole == "Admin")
        this.$router.push({
          name: "sales-create",
          query: {
            customer_reference: customer_reference,
            order_reference: order_reference,
          },
        });
      else if (this.userRole == "Magasinier") {
        this.$router.push({
          name: "storekeeper-sales-create",
          query: {
            customer_reference: customer_reference,
            order_reference: order_reference,
          },
        });
      }
    },

    async importData(data) {
      let reference = data;
      this.$router.push({
        name: "sales-items-create",
        query: { customer: reference, order: reference },
      });
      await this.$confirm({
        message: "Vous voulez importer les articles de commande : " + reference,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("sale/uploadOrderItems", reference);
          }
        },
      });
    },
  },
  async created() {
    await this.$store.dispatch("stock/getAll");
    await this.$store.dispatch("section/getAll");
    await this.$store.dispatch("item/getAll");
    await this.$store.dispatch("sale/getAllItems");
    await this.$store.dispatch("customer/getAll");
    await this.$store.dispatch("order/getAll");
  },
  watch: {},
  filters: {
    date: (value) => {
      return moment(value).format("DD-MM-YYYY");
    },
  },
};
</script>
